<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Sakin Hesap Hareketleri"
            icon="mdi mdi-account-convert"
            @clear-filters="clearFilters()"
            @click:edit="handleEditClick"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:prepend">
              <rs-select
                class="float-end me-2"
                style="max-width: 150px"
                label="Tarih Filtrele"
                :items="dateFilterList"
                @change="handleDateFilterChange"
                v-model="filterdate"
                dense
              />
            </template>
          </rs-table-title-bar>
          <v-row dense v-if="can('edit-house-income')">
            <v-col>
              <v-btn
                color="pink"
                outlined
                dark
                small
                class="me-2"
                @click="$refs.collectingReceiptNoForm.show()"
                v-if="
                  selectedItems.length === 1 &&
                  selectedItems[0].type === 'collecting'
                "
              >
                Makbuz Seri No Değiştir
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals :headers="headers" :totals="footTotals" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'incomes.houses.show',
              params: { id: item.house_id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.door_number="{ item, value }">
          <router-link
            :to="{
              name: 'definitions.houses.edit',
              params: { id: item.house_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.balance_no="{ item, value }">
          <a href="#" @click.prevent="handleBBEditClick(item)">
            <rs-table-cell-string :value="value" limit="20" />
          </a>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident_balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.payed_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident="{ item, value }">
          <router-link
            :to="{
              name: 'definitions.residents.edit',
              params: { id: item.resident_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.action_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.last_payment_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.debt_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.deferment_amount="{ value, item }">
          <rs-table-cell-number
            v-if="!item.collecting_id"
            price
            :value="value"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.debt_total_amount="{ value, item }">
          <rs-table-cell-number
            v-if="!item.collecting_id"
            price
            :value="value"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.collecting_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string limit="20" :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.block="{ item }">
          {{ item.block }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>

    <CollectingDetail ref="collectingDetailForm" />

    <CollectingReceiptNoForm
      :house="house"
      ref="collectingReceiptNoForm"
      :selected="selectedItems"
      @saved="loadList()"
    />

    <DebtForm ref="debtForm" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

import {
  CollectingDetail,
  CollectingReceiptNoForm,
  DebtForm,
} from "@/view/pages/income/forms";
import { isPage } from "../../../mixins";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions, isPage],
  computed: {
    ...mapGetters(["blocks", "clusterId"]),
  },
  components: {
    CollectingDetail,
    CollectingReceiptNoForm,
    DebtForm,
  },
  data() {
    return {
      pageMeta: {
        title: "Sakin Hesap Hareketleri",
      },
      filterdate: 0,
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Borçlu / Ödeyen",
          value: "resident",
          searchable: "text",
          width: "150px",
        },
        {
          text: "Blok",
          value: "block",
          searchable: "select",
          searchValue: "block_id",
          options: () => this.blocks,
          width: "75px",
          align: "center",
        },
        {
          text: "BB No",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "Sistem No",
          value: "balance_no",
          searchable: "text",
        },
        {
          text: "Makbuz No",
          value: "collecting_number",
          searchable: "text",
        },
        {
          text: "Tarih",
          value: "action_on",
          searchable: "date",
        },
        {
          text: "Fiş Türü",
          value: "bill_type",
          searchable: "text",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Toplam Tahakkuk",
          value: "debt_total_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ödenen Tutar",
          value: "collecting_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kişi Bakiye",
          value: "resident_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Banka / Kasa",
          value: "bank_safe",
          searchable: "bankSafe",
        },
        {
          text: "Tahakkuk Eden",
          value: "debt_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gecikme Tahakkuk",
          value: "deferment_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Son Ödeme Tarihi",
          value: "last_payment_on",
          searchable: "date",
          width: "120px",
        },
        {
          text: "Ödenen Tarih",
          value: "payed_on",
          searchable: "date",
        },
      ],
      dateFilterList: [
        {
          id: 0,
          name: "Tümü",
        },
        {
          id: 1,
          name: "Bugün",
        },
        {
          id: 2,
          name: "Son 7 Gün",
        },
        {
          id: 3,
          name: "Son 30 Gün",
        },
        {
          id: 4,
          name: "Son 6 Ay",
        },
        {
          id: 5,
          name: "Son 1 Yıl",
        },
        {
          id: 6,
          name: "Son 2 Yıl",
        },
      ],
      options: {
        sortBy: ["order_num"],
        sortDesc: [true],
      },
      search: {
        action_on: {
          min: undefined,
          max: undefined,
        },
      },
      titleBarAttrs: {
        exportUrl: "balance-activities",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.scope_and = ["resident"];
      params.cluster_id = this.clusterId;

      return params;
    },
    clearFilters() {
      this.$refs.headSearch.reset();
      this.filterdate = null;
      this.search.query = "";
      this.search.action_on = {
        min: undefined,
        max: undefined,
      };
      this.loadList();
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.resetTable();

      const params = this.getParams();
      this.isLoading = true;

      this.$api
        .query("balance-activities", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.options.page = response.data.meta.current_page;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length !== 1) {
        return false;
      }

      this.$router.push({
        name: "incomes.houses.show",
        params: {
          id: this.selectedItems[0].house_id,
        },
      });
    },
    handleBBEditClick(item) {
      if (item.enforcement_transaction_id) {
        this.$router.push({
          name: "enforcements.show",
          params: { id: item.enforcement_id },
        });
      } else if (item.type === "collecting") {
        this.$refs.collectingDetailForm.show(item.collecting_id, true);
      } else if (item.type === "debt") {
        this.$refs.debtForm.show(item.debt_id, true);
      } else if (item.type === "invoice_payment" || item.type === "invoice") {
        this.$router.push({
          name: "expenses.invoices.show",
          params: { id: item.invoice_id },
        });
      }
    },
    handleDateFilterChange(value) {
      const t = new Date();

      if (this.search.action_on === undefined) {
        this.search.action_on = {
          min: undefined,
          max: undefined,
        };
      }

      if (value === null) {
        this.search.action_on = {
          min: undefined,
          max: undefined,
        };
      } else if (value === 1) {
        const todaystart = new Date();
        todaystart.setUTCHours(0, 0, 0, 0);
        const todayend = new Date();
        todayend.setUTCHours(23, 59, 59, 999);
        // payment in today
        this.search.action_on = {
          min: todaystart,
          max: todayend,
        };
      } else if (value === 2) {
        // payment in 7 days
        this.search.action_on = {
          min: new Date(t.getFullYear(), t.getMonth(), t.getDate() - 7),
          max: t,
        };
      } else if (value === 3) {
        // payment in 30 days
        this.search.action_on.min = new Date(
          t.getFullYear(),
          t.getMonth(),
          t.getDate() - 30
        );
        this.search.action_on.max = t;
      } else if (value === 4) {
        // payment in 6 months
        this.search.action_on.min = new Date(t.getFullYear(), t.getMonth() - 6);
        this.search.action_on.max = t;
      } else if (value === 5) {
        // payment in 1 year
        this.search.action_on.min = new Date(
          t.getFullYear(),
          t.getMonth() - 12
        );
        this.search.action_on.max = t;
      } else if (value === 6) {
        // payment in 2 year
        this.search.action_on.min = new Date(
          t.getFullYear(),
          t.getMonth() - 24
        );
        this.search.action_on.max = t;
      }

      this.loadList();
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
